<template>
  <layout-dashboard :title="$route.name">
    <v-container fluid class="fill-container-height">
      <v-card>
        <v-data-table :headers="headers" :items="users"/>
      </v-card>
    </v-container>
  </layout-dashboard>
</template>

<script>

import {mapActions, mapGetters, mapMutations} from "vuex";
import moment from "moment";
import {dateTimeFormat} from "@/utils/macros/variables";

export default {
  name: "OnboardingStatuses",
  data: () => ({
    users: []
  }),

  async mounted() {
    await this.fetchAllUsers()

    this.setLoader({
      visible: false,
    })

    this.users = JSON.parse(JSON.stringify(this.getAllUsers))?.map(user => {

      const { seconds: cSeconds } = user?.createTimestamp || {}
      const { seconds: uSeconds } = user?.updateTimestamp || {}

      user.created = cSeconds && moment.unix(cSeconds).format(dateTimeFormat)
      user.updated = uSeconds && moment.unix(uSeconds).format(dateTimeFormat)

      user.paymentOperator = (user.paymentOperator || '-' ).toUpperCase()
      user.onboardingStatus = user.bluemediaVerification || '-'
      user.bluemediaServiceId = user.bluemediaServiceId || '-'

      return user
    })
  },
  beforeMount() {
    this.headers = [
      { text: 'Name', value: 'displayName' },
      { text: 'Link', value: 'link' },
      { text: 'Email', value: 'email' },
      { text: 'Recent db data change', value: 'updated' },
      { text: 'Created account', value: 'created'},
      { text: 'Current payment operator', value: 'paymentOperator'},
      { text: 'Onboarding status', value: 'onboardingStatus'},
      { text: 'Service id', value: 'bluemediaServiceId'},
    ]
  },
  methods: {
    ...mapMutations('components', ['setLoader']),
    ...mapActions('statistics', ['fetchAllUsers'])
  },
  computed: {
    ...mapGetters('statistics', ['getAllUsers'])
  }
}
</script>
